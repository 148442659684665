<template>
  <div
    class="v_fun_task_recommend bbox"
  >
    <div class="category_group d-flex align-stretch">
      <template
        v-for="(item, index) in category"
      >
        <div
          :key="index"
          class="category bbox d-flex flex-column justify-space-between align-start"
        >
          <div class="info_group">
            <div class="name_group d-flex align-center pb-12">
              <img
                :src="require(`@/assets/images/recommend/${item.routername}.png`)"
                alt=""
                class="icon mr-10"
              >
              <div class="name"
                :style="{color: item.color}"
              >{{item.name}}</div>
            </div>
            <div class="descript pb-20">{{item.descript}}</div>
          </div>
          <div class="button"
            :style="{color: item.color, 'background-color': item.background}"
            @click.stop="goToPannel(item.routername)"
          >点击进入{{item.name}} ></div>

        </div>
      </template>
    </div>
    <div class="vip_group un_sel d-flex align-center">
      <div
        class="user_group d-flex align-center justify-start bbox flex-shrink-0"
        :class="{active: userinfo.userVip}"
      >
        <img
          :src="`${$avatar_url}${userinfo.thumbnail}@!small200`" alt=""
          class="avatar"
          width="80"
          height="80"
        >
        <div class="info_group d-flex flex-column align-start">
          <div class="info">
            <div class="name_group d-flex align-center flex-wrap">
              <div class="name">{{userinfo.userName}}</div>
              <img
                src="~@/assets/images/recommend/vip.png" alt="" class="vip"
                v-if="userinfo.userVip"
              >
              <div class="normal my-5 ml-4"
                v-else
              >普通用户</div>
            </div>
            <div class="expired_time pb-16"
              v-if="userinfo.vipEndDate && userinfo.userVip"
            >{{$moment(userinfo.vipEndDate).format('YYYY-MM-DD')}} 到期</div>
            <div class="expired_time pb-16"
              v-else
            ></div>
          </div>
          <div class="button"
            @click.stop="touchVip()"
          >{{userinfo.userVip?'立即续费VIP会员':'立即成为VIP会员'}}</div>
        </div>
      </div>
      <div class="vip_list bbox d-flex align-stretch">
        <div class="list d-flex align-stretch flex-column">
          <template
            v-for="(item, index) in vip_list1"
          >
            <div
              :key="index"
              class="item d-flex align-center"
            >
              <img
                :src="require(`@/assets/images/personal_center/vip/vip${item.icon}.png`)"
                alt=""
                class="icon"
              >
              <div class="descript">{{item.descript}}</div>
            </div>
          </template>
        </div>
        <div class="list d-flex align-stretch flex-column">
          <template
            v-for="(item, index) in vip_list2"
          >
            <div
              :key="index"
              class="item d-flex align-center"
            >
              <img
                :src="require(`@/assets/images/personal_center/vip/vip${item.icon}.png`)"
                alt=""
                class="icon"
              >
              <div class="descript">{{item.descript}}</div>
            </div>
          </template>
        </div>
        <div class="list d-flex align-stretch flex-column">
          <template
            v-for="(item, index) in vip_list3"
          >
            <div
              :key="index"
              class="item d-flex align-center"
            >
              <img
                :src="require(`@/assets/images/personal_center/vip/vip${item.icon}.png`)"
                alt=""
                class="icon"
              >
              <div class="descript">{{item.descript}}</div>
            </div>
          </template>
        </div>
      </div>

    </div>
    <div class="form_group bbox d-flex align-stretch justify-space-between">
      <template
        v-for="(item, index) in form_list"
      >
        <div class="list bbox d-flex flex-column justify-space-between align-start flex-shrink-0"
          :key="index"
        >
          <div class="info_group">
            <div class="name">{{item.name}}</div>
            <div class="descript">{{item.descript}}</div>
          </div>
          <div class="button"
            @click.stop="touchApply({enquireType: item.enquireType, title: item.title})"
          >{{item.button}}</div>
          <img
            :src="require(`@/assets/images/recommend/${item.icon}.png`)"
            alt=""
            class="icon"
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {UserInfo} from '@/types'
import tools from '@/api/tools'
import store from '@/store'
import global from '@/api/global'
export default {
  data(){
    return {
      category: [
        {name:'沟通频道', descript: '所有的沟通行为，都在沟通频道进行，包括单独的联系人沟通、多人工作组沟通、都会被罗列在沟通频道的列表中。', routername: 'fun_task', color: '#0054A7', background: '#E5EDF6'},
        {name:'联系人频道', descript: '联系人频道可以对联系人进行集中管理，包括添加联系人，查看联系人个人资料。', routername: 'fun_contact', color: '#428B81', background: '#EDF4F3'},
        {name:'模板频道',descript: '这里集中了模板商城、我的任务、销售/购买的模板，草稿箱、回收站等管理入口。', routername: 'fun_template', color: '#0D6E8F', background: '#E6F0F3'},
        {name:'个人中心', descript: '包含了编辑个人资料、个性密码、激活产品、查看会员权限与消费、退出登录等操作。', routername: 'personal_center', color: '#905A5A', background: '#F3EEEE'},
      ],
      vip_list1: [
        {icon:'1', descript: '查看联系人能力画像的具体数值'},
        {icon:'4', descript: '创建任务自定义标签'},
      ],
      vip_list2: [
        {icon:'2', descript: '收藏“模板商城”中的任务'},
        {icon:'5', descript: '在“我收藏的”中创建文件夹'},
      ],
      vip_list3: [
        {icon:'3', descript: '使用“智慧桌”控件'},
      ],
      form_list: [
        {name: '签约讲师', descript: '会E人长期招募有意愿进行教学内容研发的行业专家成为平台讲师，申请通过并成为讲师后，您可以将自己擅长领域的经验和专业技能转化为教学内容并制作成教学模板在线进行销售，提交申请后，会有讲师负责人与您进行具体沟通。', button: '提交申请', title: '讲师合作申请', icon: 'form1', enquireType: 2, },
        {name: '大宗采购', descript: '对于有大宗采购教学模板、vip账号权限等需求的院校用户，您可以提交您的采购需求给我们，我们将有专门的商务销售与您对接沟通。', button: '提交需求', title: '大宗采购需求', icon: 'form2', enquireType: 3, },
        {name: '商务合作', descript: '在致力于实践教学的道路上，我们不限制任何可能性的合作发展，如果您有任何建设性的思路和想法，您可以告诉我们，我们欢迎任何可能性的合作洽谈。', button: '提交想法', title: '商务合作', icon: 'form3', enquireType: 4, },
      ],
      userinfo: new UserInfo(tools.getCookie('user')),

    }
  },
  computed: {
    user_data_update(){
      return store.state.user_data_update
    },
  },
  watch: {
    user_data_update: function(val){
      if(val){
        this.$set(this, 'userinfo', new UserInfo(tools.getCookie('user')))
      }
    }
  },
  methods: {
    touchApply(params){
      const {title, enquireType} = params
      this.$consulting({
        title,
        subtitle: '您好，请确认并填写以下信息，方便工作人员与您及时联系。',
        enquireType,
      })
    },
    touchVip(){
      const {userinfo} = this
      this.$become_member({
        renew: Boolean(userinfo.userVip),
      })
    },
    goToPannel(routername){
      if(routername === 'fun_task'){
        this.$notice({desc: '当前已是沟通频道!'})
        return
      }
      this.$router.push({name:`${routername}_index`})
    },
    showAddressBookPrompt(){
      const {userinfo} = this
      const {ischeckphones} = userinfo
      if(!ischeckphones){
        this.$openAddressBookPrompt({
          onSure: async ()=>{
            const res = await global.doPost({
              url: '/projectFriends/noRemindcheckPhones'
            })
            if(res.message === 'success'){
              tools.setCookie('user', Object.assign(
                {},
                this.userinfo,
                {ischeckphones: 1}
              ))
              store.commit('set_user_data_update', true)
            }
          }
        })
        return
      }
      return true
    },
    showGift(){
      const {userinfo} = this
      // 打开礼包
      if(userinfo){
        const {isgetgift} = userinfo
        if(!isgetgift){
          this.$taskGiftBag({
            onSure: async ()=>{
              const res = await global.doPost({
                url: '/projectCustom/getgiftbag',
              })
              if(res.message === 'success'){
                tools.setCookie('user', Object.assign(
                  tools.getCookie('user'),
                  {
                    isgetgift: 1,
                  }
                ))
                store.commit('set_user_data_update', true)
                this.$openConfirm({
                  title: '获取成功',
                  desc: '您可以前往“模板频道-我收藏的”随时查看模板，也可以在任意对话窗口内通过调用发布使用。',
                  sure: '暂不前往',
                  cancel: '前往我收藏的',
                  sureType: 'light',
                  cancelType: 'dark',
                  onSure: ()=>{
                    this.showAddressBookPrompt()
                  },
                  onCancel: ()=>{
                    this.$router.push({name: 'fun_template_my', params: {type: 'custom'}})
                  }
                })
              }
            }
          })
          return
        }else{
          this.showAddressBookPrompt()
        }
      }
      return true
    }
  },
  mounted(){
    this.showGift()
  }
}
</script>

<style lang="scss" scoped>
.v_fun_task_recommend{
  min-width: 1000px;
  height: 100%;
  .category_group{
    .category{
      flex: 1;
      padding: 40px;
      background-color: #fff;
      border-right: 1px solid #f0f0f0;
      &:first-child{
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:last-child{
        border-right: none;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      .info_group{
        .name{
          font-size: 18px;
          font-weight: bold;
        }
        .descript{
          color: #445566;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .button{
        font-size: 14px;
        line-height: 36px;
        border-radius: 4px;
        padding: 0 12px;
      }
    }
  }
  .vip_group{
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 30px;
    .user_group{
      @include background;
      width: 350px;
      height: 160px;
      background-image: url(~@/assets/images/recommend/normal_bg.png);
      padding: {
        left: 30px;
      }
      .avatar{
        margin-right: 30px;
        border-radius: 4px;
      }
      &.active{
        background-image: url(~@/assets/images/recommend/vip_bg.png);
        .info_group{
          .info{
            .name_group{
              .name{
                color: #884706;
              }
            }
          }
          .button{
            background-color: #1F1E1D;
            background-image: none;
          }
        }
      }
      .info_group{
        .info{
          .name_group{
            .name{
              color: #333;
              font-size: 18px;
            }
            .normal{
              padding: 0 7px;
              border-radius: 4px;
              background-color: #D8D8D8;
              color: #666;
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
        .expired_time{
          font-size: 14px;
          color: #B87C41;
        }
        .button{
          color: #FFDC9D;
          font-size: 14px;
          line-height: 36px;
          padding: 0 30px;
          border-radius: 4px;
          background-image: linear-gradient(135deg, #605853 0%, #2F2E32 100%);
        }
      }
    }
    .vip_list{
      .list{
        flex: 1;
        .item{
          margin: 10px 0;
          .icon{
            margin: 0 10px;
            margin-left: 40px;
          }
          .descript{
            font-size: 16px;
            color: #555;
          }
        }
      }
    }
  }
  .form_group{
    margin-top: 30px;
    .list{
      position: relative;
      padding: 40px;
      background-color: #fff;
      border-radius: 10px;
      flex: 1;
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
      .info_group{
        position: relative;
        z-index: 3;
        .name{
          font-size: 18px;
          line-height: 20px;
          padding-left: 10px;
          border-left: 4px solid #82929C;
          color: #40474E;
          font-weight: bold;
          margin-bottom: 12px;
        }
        .descript{
          font-size: 16px;
          color: #6C7681;
          line-height: 24px;
          padding-bottom: 16px;
        }
      }
      .button{
        position: relative;
        z-index: 3;
        border-radius: 4px;
        line-height: 34px;
        border:1px solid $main_blue;
        color: $main_blue;
        padding: 0 20px;
      }
      .icon{
        position: absolute;
        bottom: 16px;
        right: 20px;
        z-index: 2;
      }
    }
  }
}
</style>
